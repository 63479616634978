import React, { useEffect } from "react";
import "./AppBar.css";
import logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
const AppBar = ({ logo }) => {
    const { user } = useAuth();
    // console.log("Appbar", user);

    return (
        <div className="app_bar mt-0">
            <NavLink
                to="/"
                className={(navLink) =>
                    navLink.isActive
                        ? "app_bar_menu active"
                        : "app_bar_menu inactive"
                }
            >
                <img
                    src={logo}
                    alt="logo"
                    width={10}
                    height={10}
                    className="h-[30px] w-auto"
                />
            </NavLink>

            {/* <NavLink
        to={
          user?.healthId?.length > 0
            ? "/abha/healthCardPage"
            : "/abha/registerabdm"
        }
        className={(navLink) =>
          navLink.isActive ? "app_bar_menu active" : "app_bar_menu"
        }
      >
        <div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M18.3333 6.50829V10.4166H14.9333C14.8333 10.4083 14.6166 10.2833 14.5666 10.1916L13.7 8.54996C13.3583 7.89996 12.7666 7.53329 12.1333 7.56663C11.5 7.59996 10.9583 8.02496 10.6833 8.71663L9.53329 11.6L9.36663 11.1666C8.95829 10.1083 7.79163 9.30829 6.64163 9.30829L1.66663 9.33329V6.50829C1.66663 3.47496 3.47496 1.66663 6.50829 1.66663H13.4916C16.525 1.66663 18.3333 3.47496 18.3333 6.50829Z"
              fill="#7C7C7C"
            />
            <path
              d="M18.3334 13.4909V11.6659H14.9334C14.3751 11.6659 13.7167 11.2659 13.4584 10.7742L12.5917 9.13256C12.3584 8.69089 12.0251 8.71589 11.8417 9.17423L9.92506 14.0159C9.71672 14.5576 9.36672 14.5576 9.15006 14.0159L8.20006 11.6159C7.97506 11.0326 7.27506 10.5576 6.65006 10.5576L1.66672 10.5826V13.4909C1.66672 16.4742 3.41672 18.2742 6.35839 18.3242C6.45006 18.3326 6.55006 18.3326 6.64172 18.3326H13.3084C13.4334 18.3326 13.5584 18.3326 13.6751 18.3242C16.6001 18.2576 18.3334 16.4659 18.3334 13.4909ZM1.66672 10.5834V13.3417C1.65006 13.0751 1.64172 12.7917 1.64172 12.5001V10.5834H1.66672Z"
              fill="#7C7C7C"
            />
          </svg>
          <p>Create Health ID</p>
        </div>
      </NavLink> */}
            {/* <NavLink
        to="/medicine"
        className={(navLink) =>
          navLink.isActive ? "app_bar_menu active" : "app_bar_menu"
        }
      >
        <div>
          <svg width="20" height="20" viewBox="0 0 14 16" fill="none">
            <path
              d="M13.1424 9.5451L12.2949 8.1351C12.1074 7.8276 11.9424 7.2351 11.9424 6.8751V5.4726C11.9424 2.7501 9.72991 0.537598 7.01491 0.537598C4.29241 0.545098 2.07991 2.7501 2.07991 5.4726V6.8676C2.07991 7.2276 1.91491 7.8201 1.73491 8.1276L0.887408 9.5376C0.564908 10.0851 0.489908 10.7076 0.692408 11.2476C0.894908 11.7951 1.35241 12.2301 1.95241 12.4251C2.76241 12.6951 3.57991 12.8901 4.41241 13.0326C4.49491 13.0476 4.57741 13.0551 4.65991 13.0701L4.98991 13.1151C5.18491 13.1451 5.37991 13.1676 5.58241 13.1826C6.52781 13.2717 7.4795 13.2717 8.42491 13.1826C8.59741 13.1676 8.76991 13.1526 8.93491 13.1301C9.06991 13.1151 9.20491 13.1001 9.33991 13.0776C9.42241 13.0701 9.50491 13.0551 9.58741 13.0401C10.4274 12.9051 11.2599 12.6951 12.0699 12.4251C12.3501 12.3314 12.6059 12.1764 12.8187 11.9715C13.0315 11.7665 13.1959 11.5166 13.2999 11.2401C13.5099 10.6776 13.4499 10.0626 13.1424 9.5451ZM7.56241 6.5001C7.56241 6.65127 7.50235 6.79625 7.39546 6.90315C7.28856 7.01004 7.14358 7.0701 6.99241 7.0701C6.84123 7.0701 6.69625 7.01004 6.58936 6.90315C6.48246 6.79625 6.42241 6.65127 6.42241 6.5001V4.1751C6.42241 4.02392 6.48246 3.87894 6.58936 3.77205C6.69625 3.66515 6.84123 3.6051 6.99241 3.6051C7.14358 3.6051 7.28856 3.66515 7.39546 3.77205C7.50235 3.87894 7.56241 4.02392 7.56241 4.1751V6.5001ZM9.12241 14.0076C8.96469 14.4442 8.67637 14.8217 8.29662 15.0887C7.91688 15.3557 7.46414 15.4994 6.99991 15.5001C6.40741 15.5001 5.82241 15.2601 5.40991 14.8326C5.16991 14.6076 4.98991 14.3076 4.88491 14.0001C4.98241 14.0151 5.07991 14.0226 5.18491 14.0376C5.35741 14.0601 5.53741 14.0826 5.71741 14.0976C6.14491 14.1351 6.57991 14.1576 7.01491 14.1576C7.44241 14.1576 7.86991 14.1351 8.28991 14.0976C8.44741 14.0826 8.60491 14.0751 8.75491 14.0526L9.12241 14.0076Z"
              fill="#7C7C7C"
            />
          </svg>{" "}
          <p>Medicine Notifier</p>
        </div>
      </NavLink> */}
            <NavLink
                to="/bookings"
                className={(navLink) =>
                    navLink.isActive ? "app_bar_menu active" : "app_bar_menu"
                }
            >
                <div>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                            d="M12.3334 0.666626H5.66669C2.33335 0.666626 0.666687 2.33329 0.666687 5.66663V16.5C0.666687 16.9583 1.04169 17.3333 1.50002 17.3333H12.3334C15.6667 17.3333 17.3334 15.6666 17.3334 12.3333V5.66663C17.3334 2.33329 15.6667 0.666626 12.3334 0.666626ZM11.9167 9.62496H9.62502V11.9166C9.62502 12.2583 9.34169 12.5416 9.00002 12.5416C8.65835 12.5416 8.37502 12.2583 8.37502 11.9166V9.62496H6.08335C5.74169 9.62496 5.45835 9.34163 5.45835 8.99996C5.45835 8.65829 5.74169 8.37496 6.08335 8.37496H8.37502V6.08329C8.37502 5.74163 8.65835 5.45829 9.00002 5.45829C9.34169 5.45829 9.62502 5.74163 9.62502 6.08329V8.37496H11.9167C12.2584 8.37496 12.5417 8.65829 12.5417 8.99996C12.5417 9.34163 12.2584 9.62496 11.9167 9.62496Z"
                            fill="#7C7C7C"
                        />
                    </svg>
                    <p>My Bookings</p>
                </div>
            </NavLink>
        </div>
    );
};

export default AppBar;
