import React from "react";
import { useNavigate } from "react-router-dom";
import { getDistance } from "../../../utils";
import doctorAvatar from "../../../assets/images/doctorAvatar.png";
import useAppContext from "../../context/AppContext";
import mockData from "../../../assets/mockData";
const HomeDocCard = ({ doctor, onClickBack }) => {
    const navigate = useNavigate();
    const { deviceLocation } = useAppContext();
    return (
        <div
            key={doctor._id}
            className="home_doctor_listing_card"
            onClick={() => onClickBack(doctor._id)}
        >
            <div className="doctor_listing_card_img">
                <img
                    src={doctor?.image?.fileUrl || doctorAvatar}
                    className="img-fluid"
                    alt="Loading Doctor..."
                    style={{ objectFit: "fill" }}
                />
            </div>
            <div className="home_doctor_listing_content">
                <h1 className="docter_listing_name">
                    {doctor?.name ? doctor?.name : "No Docter Name"}
                </h1>
                <p className="docter_speciality_and_exp">
                    {doctor?.speciality?.length
                        ? `${doctor?.speciality.map((x) => x.name).join(",")}`
                        : "-- No Speciality --"}
                </p>
                <p className="docter_speciality_and_exp">
                    <span className="">
                        <strong>
                            {doctor?.experience ? doctor?.experience : "0"} +{" "}
                        </strong>{" "}
                        years of experience
                    </span>
                </p>
            </div>
        </div>
    );
};

export default HomeDocCard;
