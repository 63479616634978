import axiosInstance from "../index";

export const getDomainData = async (domain) => {
  try {
    const response = await axiosInstance.get(`/p/domain?domain=${domain}`);
    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Could not get doctor");
    }
  } catch (err) {
    console.log(err);
    return err?.response;
  }
};
