import React from "react";
import "./YourBookingsCard.css";
import image3 from "../../../assets/images/doctorAvatar.png";
import CalenderSvg from "./CalenderSvg.svg";
import { useState, useEffect } from "react";
import mockData from "../../../assets/mockData";
import { cancelAppointment } from "../../../api/patient/appointment";
import useAppContext from "../../context/AppContext";
import Spinner from "../../Spinner";
import { getDistance } from "../../../utils";
import moment from "moment";
import CancellationInfoModal from "./CancellationInfoModal";
import ClickOutHandler from "react-onclickout";
import { useNavigate } from "react-router-dom";
import PrescriptionPDF from "../../YourBookings/PrecriptionPDF/PrescriptionPDF";
import { useRef } from "react";
import ReactToPdf from "react-to-pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// NOTES:
// PENDING:  Download Prescription

function YourBookingsCard({
  appointment,
  setRerenderAppoinments,
  appointmentStatus,
}) {
  const { openSnackbar, deviceLocation } = useAppContext();
  const [displayCancelBtn, setCanceldisplayBtn] = useState();
  const [DownloadisplayBtn, setDownloaddisplayBtn] = useState(true);
  const [AppointmentBtnBg, setAppointmentBtnBg] = useState();
  const [Infodiv, SetInfodiv] = useState(false);
  const [RefundIcon, SetRefundIcon] = useState(
    appointment?.cancel?.isCancelled
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const pdfRef = React.createRef();
  // console.log(appointment);
  async function onClickCancelAppointment() {
    setLoading(true);
    const response = await cancelAppointment(appointment._id);
    if (response.status === 200) {
      setRerenderAppoinments((prev) => !prev);
      openSnackbar({
        severity: "success",
        message: "Appointment cancelled successfully",
      });
    } else {
      openSnackbar({
        severity: "error",
        message: "Error cancelling appointment",
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (appointmentStatus == "Cancelled") {
      setCanceldisplayBtn(true);
      SetRefundIcon(true);
      SetInfodiv(false);
    } else if (appointmentStatus == "On-going") {
      setCanceldisplayBtn(true);
      SetInfodiv(false);
    } else if (appointmentStatus == "Yet to visit") {
      setCanceldisplayBtn(false);
      SetInfodiv(false);
    } else if (appointmentStatus == "Completed") {
      setCanceldisplayBtn(true);
      setDownloaddisplayBtn(false);
      SetInfodiv(false);
    } else if (appointmentStatus == "Refunded") {
      setCanceldisplayBtn(true);
      setDownloaddisplayBtn(false);
    }
  }, []);
  const generatePdf = () => {
    if (pdfRef?.current == null) return;
    html2canvas(pdfRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 200, 100);
      pdf.save("prescription.pdf");
    });
  };
  const title1 =
    "The booking has been cancelled due to the unavailability of doctor.";
  const title2 =
    "Refund of ₹ 200/- has been initiated to the ource of original payment method.";
  return (
    <div>
      <div className="appointment_bookings_card">
        {RefundIcon && (
          <div>
            <svg
              onClick={() => setOpen(!open)}
              className="info_modal_icon"
              viewBox="0 0 11 11"
              fill="none"
            >
              <g clipPath="url(#clip0_1947_112)">
                <path
                  d="M5.49919 7.33374V5.04207M5.49919 0.917073C2.97835 0.917073 0.915853 2.97957 0.915853 5.50041C0.915853 8.02124 2.97835 10.0837 5.49919 10.0837C8.02002 10.0837 10.0825 8.02124 10.0825 5.50041C10.0825 2.97957 8.02002 0.917073 5.49919 0.917073Z"
                  stroke="#DE3435"
                  strokeWidth="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.50293 3.66626H5.49674"
                  stroke="#DE3435"
                  strokeWidth="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1947_112">
                  <rect
                    width="11"
                    height="11"
                    fill="white"
                    transform="matrix(-1 0 0 -1 11 11)"
                  />
                </clipPath>
              </defs>
            </svg>
            {open && (
              <ClickOutHandler onClickOut={() => setOpen(false)}>
                <CancellationInfoModal
                  title1={appointment?.cancel?.reason}
                  date={appointment?.cancel?.date}
                />
              </ClickOutHandler>
            )}
          </div>
        )}
        <div className="YourBooking_home_doctor_listing_card">
          <div className="doctor_listing_card_img">
            <img
              src={appointment?.doctor?.image?.fileUrl || image3}
              className="img-fluid"
              alt="Loading Doctor..."
            />
          </div>
          <div className="home_doctor_listing_content">
            <h1 className="docter_listing_name">
              {appointment?.doctor?.name}
              <button className="patient_type_chip">Offline Visit</button>
            </h1>
            <div className={`${Infodiv ? "d-none" : " "}`}>
              <p className="d-flex align-items-center docter_speciality_and_exp">
                <span>{appointment?.doctor?.specialities?.[0]}</span>
                <ul>
                  <li>
                    {appointment?.doctor?.experience &&
                      appointment?.doctor?.experience}{" "}
                    + years of experience{" "}
                  </li>
                </ul>
              </p>
              <p className="Yourbooking_doctor_address">
                <svg width="12" height="12" viewBox="0 0 11 11" fill="none">
                  <rect width="11" height="11" fill="url(#pattern1)" />
                  <defs>
                    <pattern
                      id="pattern1"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        xlinkHref="#image0_468_237"
                        transform="scale(0.00390625)"
                      />
                    </pattern>
                    <image
                      id="image0_468_237"
                      width="256"
                      height="256"
                      xlinkHref={mockData?.doctor?.image}
                    />
                  </defs>
                </svg>
                {appointment?.clinic?.address?.street},{" "}
                {appointment?.clinic?.address?.area},{" "}
                {appointment?.clinic?.address?.city},{" "}
                {appointment?.clinic?.address?.state},{" "}
                {appointment?.clinic?.address?.pincode}
              </p>
              <div className="home_doctor_visit_info">
                {/* <div className="docter_listing_card_rating">
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path
                      d="M5.75146 10.9999C5.43485 10.958 5.11825 10.9226 4.80165 10.8723C4.22163 10.7793 3.66726 10.5853 3.11415 10.3894C3.08249 10.3782 3.06318 10.3256 3.04481 10.2889C3.03627 10.2719 3.04481 10.2461 3.04481 10.2235C3.04481 8.35184 3.04408 6.47998 3.0426 4.60791C3.0426 4.50616 3.07046 4.45512 3.16038 4.41161C3.53808 4.22774 3.89996 4.019 4.21625 3.72945C4.57971 3.39737 4.78233 2.96485 4.94 2.50779C5.17419 1.83518 5.30361 1.12843 5.32341 0.413897C5.32911 0.19502 5.45195 0.0311066 5.64856 0.0131121C6.12347 -0.0304017 6.58381 0.0235816 6.9783 0.340284C7.36519 0.652732 7.59219 1.0617 7.59567 1.57045C7.604 2.25889 7.51874 2.94512 7.34239 3.60905C7.31453 3.71179 7.27337 3.81092 7.23253 3.92903H7.39526C8.29252 3.92903 9.18956 3.92903 10.0864 3.92903C10.4682 3.92903 10.8073 4.03405 11.084 4.31967C11.4494 4.69657 11.5048 5.3218 11.2072 5.75726C11.178 5.80012 11.1483 5.842 11.1122 5.89206C11.3772 6.22185 11.4674 6.59842 11.3503 7.01164C11.2331 7.42486 10.9634 7.68496 10.5743 7.81321C10.6933 8.71522 10.5508 9.00968 9.84419 9.37611C9.85084 9.60513 9.88978 9.82401 9.85622 10.0305C9.77929 10.5084 9.39366 10.8742 8.91781 10.9701C8.89017 10.9772 8.86299 10.9862 8.83644 10.9969L5.75146 10.9999Z"
                      fill="white"
                    />
                    <path
                      d="M0.290963 10.9995C0.0709234 10.9066 -0.000629048 10.7352 4.15942e-06 10.494C0.00538642 8.47507 0.00317021 6.4561 0.00317021 4.43712C0.00317021 4.06513 0.129812 3.93197 0.488207 3.93164C0.930185 3.93164 1.37227 3.93164 1.81446 3.93164C2.14404 3.93164 2.28303 4.07658 2.28303 4.41684C2.28303 6.4453 2.28388 8.47311 2.28557 10.5003C2.28557 10.7407 2.2099 10.9132 1.98479 10.9992L0.290963 10.9995Z"
                      fill="white"
                    />
                  </svg>
                  {YourBookingCardProps.Likes}%
                </div> */}
                <div className="home_consultation_fee">
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    heigth="16"
                    width="16"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {appointment?.fees?.total}
                </div>
                <div className="home_kms_distance">
                  <span className="kmph">
                    {getDistance(
                      deviceLocation,
                      appointment?.clinic?.address?.location
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-2">
          <div className="container">
            <div className="row">
              <div className="d-flex d-inline d-flex justify-content-between">
                <div className="" id="YourBookingsCardBookDateNtimeHead">
                  Booking Date & Time
                </div>
                {/* <div className="">
                  <button
                    type="button"
                    className="btn btn-warning AppointmentStatusBtn"
                    id={`${AppointmentBtnBg}`}
                  >
                    {YourBookingCardProps?.appointmentStatus}
                  </button>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div
                className="col YourBookingsCardCalanderNdate"
                id="YourBookingsCardDateDiv"
              >
                <div className="mt-2" id="YourBookingsCardDateDivtext">
                  <img src={CalenderSvg} alt="CalenderSvg" />
                  <span className="p-2">
                    On {moment(appointment.date).format("DD MMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col YourBookingsCardCalanderNdate">
                <div className="mt-2">
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <path
                      d="M5.82046 11H5.17554C4.99143 10.9769 4.80625 10.9602 4.62348 10.9298C3.47442 10.738 2.47286 10.2435 1.64867 9.42028C0.412668 8.18546 -0.131314 6.68456 0.0266858 4.94666C0.132737 3.78017 0.58951 2.74899 1.36982 1.87278C2.40637 0.709243 3.71048 0.0826833 5.26706 0.0068182C6.35233 -0.0459108 7.37273 0.206973 8.30162 0.773271C9.72954 1.64411 10.6065 2.9053 10.9144 4.55308C10.9529 4.75942 10.972 4.96926 11 5.17749V5.82207C10.9911 5.89928 10.9822 5.97676 10.9736 6.05397C10.842 7.23768 10.3898 8.2815 9.59173 9.16686C8.7444 10.1068 7.69466 10.6936 6.44546 10.9145C6.23847 10.951 6.02852 10.9718 5.82019 11H5.82046ZM5.50042 0.85371C2.93878 0.850751 0.857059 2.92843 0.8506 5.49467C0.84414 8.05202 2.92263 10.1356 5.49046 10.1456C8.05103 10.1555 10.1462 8.06413 10.1457 5.49924C10.1451 2.9357 8.0661 0.856669 5.50042 0.85371Z"
                      fill="black"
                    />
                    <path
                      d="M5.00238 4.45396C5.00238 4.09633 4.99828 3.7387 5.00396 3.38107C5.00837 3.10544 5.35218 2.93041 5.68778 3.02665C5.89155 3.08499 6.00478 3.21913 6.00478 3.40794C6.00541 4.01318 6.00951 4.61842 6.001 5.22366C5.99942 5.33185 6.04799 5.39318 6.16375 5.45496C6.70186 5.74208 7.23239 6.03655 7.7664 6.32757C7.95218 6.42886 8.04018 6.55956 7.98246 6.72654C7.89604 6.97645 7.50712 7.07981 7.21158 6.93143C7.02516 6.83771 6.84758 6.73504 6.66653 6.63604C6.21012 6.38683 5.75749 6.13348 5.2954 5.89C5.08912 5.78113 4.99544 5.64791 5.00017 5.46277C5.009 5.12673 5.00269 4.79023 5.00269 4.45396H5.00238Z"
                      fill="black"
                    />
                  </svg>

                  <span className="pt-2 ps-2">
                    {moment(appointment?.slot?.startTime, "HH:mm").format(
                      "hh:mm A"
                    ) +
                      " - " +
                      moment(appointment?.slot?.endTime, "HH:mm").format(
                        "hh:mm A"
                      )}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`container d-flex ${
                displayCancelBtn ? "d-none" : " "
              } `}
              id="CancelAppointmentBtnRow"
            >
              <div className="row">
                <button
                  id="CancelAppointmentBtn"
                  onClick={onClickCancelAppointment}
                >
                  {loading ? <Spinner color="#be2200" /> : "Cancel Appointment"}
                </button>
              </div>
            </div>
            <div className={`py-1 ${DownloadisplayBtn ? "d-none" : " "} `}>
              <div className="d-flex justify-content-between align-items-center">
                <button id="DownloadPrescriptionBtn" onClick={generatePdf}>
                  Download Prescription
                </button>
                {/* <ReactToPdf
                  targetRef={pdfRef}
                  filename="div-blue.pdf"
                  x={0.5}
                  y={0.5}
                  scale={0.8}
                >
                  {({ toPdf }) => (
                    <button id="DownloadPrescriptionBtn" onClick={toPdf}>
                      Generate pdf
                    </button>
                  )}
                </ReactToPdf> */}
                <button className="view_invoice_cta">View Invoice</button>
              </div>
              <div className="prescription_download_container">
                <PrescriptionPDF appointment={appointment} ref={pdfRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourBookingsCard;
