import moment from "moment";
import React, { useState } from "react";
import "./PrescriptionPDF.scss";
import { forwardRef } from "react";
const PrescriptionPDF = forwardRef((props, ref) => {
  const [appointmentData, setAppointmentData] = useState(props.appointment);
  // console.log(appointmentData);

  return (
    <div ref={ref}>
      <div className="PrescriptionPDF_box">
        <div className="prescription_clinic_box">
          <div className="prescription_clinic_inner_box">
            <div className="">
              <h3>{appointmentData?.clinic?.name ?? "CLINIC NAME"}</h3>
              <h4>{"Dr. " + appointmentData?.doctor?.name ?? "DOCTOR NAME"}</h4>
              <h5>{appointmentData?.doctor?.education ?? "EDUCATION"}</h5>
            </div>
            <div className="">
              <p>Contact: {appointmentData?.doctor?.phone ?? "0000000000"}</p>
              <p>Email: {appointmentData?.doctor?.email}</p>
              <h6>
                Address:{" "}
                {appointmentData?.clinic?.address?.street +
                  ", " +
                  appointmentData?.clinic?.address?.area +
                  ", " +
                  appointmentData?.clinic?.address?.city +
                  " - " +
                  appointmentData?.clinic?.address?.pincode}
              </h6>
            </div>
          </div>
        </div>

        <div className="prescription_patient_container">
          <div className="prescription_patient_box">
            <h2>
              Patient Details <span>Date: {moment().format("DD/MM/YYYY")}</span>
            </h2>
            <p className="prescription_patient_box_name">
              {appointmentData?.unregisteredPatient?.name ??
                appointmentData?.patient?.name}
              , (
              {appointmentData?.unregisteredPatient?.gender?.charAt(0) ??
                appointmentData?.patient?.gender?.charAt(0)}
              ) /{" "}
              {moment().diff(
                moment(
                  appointmentData?.unregisteredPatient?.dateOfBirth ??
                    appointmentData?.patient?.dateOfBirth
                ),
                "years"
              )}{" "}
              year
            </p>
          </div>
        </div>

        <div className="patient_prescription_content">
          <div className="prescription_item">
            <h1>Complain</h1>
            <ul>
              {appointmentData?.prescription?.complain?.length
                ? appointmentData.prescription.complain.map((item, id) => (
                    <li key={id}>{item}</li>
                  ))
                : "No Complains Found"}
            </ul>
          </div>
          <div className="prescription_item">
            <h1>Diagnosis</h1>
            <ul>
              {appointmentData?.prescription?.diagnosis?.length
                ? appointmentData.prescription.complain.map((item, id) => (
                    <li key={id}>{item}</li>
                  ))
                : "No Diagnosis Found"}
            </ul>
          </div>
          <div className="prescription_item">
            <h1>Treatment</h1>
            <ul>
              {appointmentData?.prescription?.treatment?.length
                ? appointmentData.prescription.complain.map((item, id) => (
                    <li key={id}>{item}</li>
                  ))
                : "No Treatments Found"}
            </ul>
          </div>
        </div>

        <div className="prescription_test_container">
          <div className="prescription_test_item">
            <h1>Test</h1>
            <ol>
              {appointmentData?.prescription?.test?.length ? (
                appointmentData?.prescription?.test?.map((test, index) => {
                  return <li key={index}>{test}</li>;
                })
              ) : (
                <p>-</p>
              )}
            </ol>
          </div>
        </div>

        <div className="prescription_drugs_table">
          <table>
            <thead>
              <tr>
                <th>Drug Name</th>
                <th>Unit</th>
                <th>Dosage</th>
                <th>Duration</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {appointmentData?.prescription?.drugs &&
                appointmentData?.prescription?.drugs.map((drug, index) => {
                  return (
                    <tr key={index}>
                      <td>TAB- {drug?.name ?? "Unnamed"}</td>
                      <td>{drug?.units ?? "0"} TAB</td>
                      <td>{drug?.dosage ?? "0-0-0"}</td>
                      <td>{drug?.duration ?? "0"} days</td>
                      <td>{drug?.description ?? "-"}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="prescription_follow_update">
          <div className="prescription_follow_update_wrapper">
            <h1>Follow Up Date</h1>
            <p>{moment().format("DD/MM/YYYY")}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrescriptionPDF;
